import axios from '@/plugins/axios';

export default {
  getOrganisations(config = {}) {
    return axios.get('/v1/master/organisations', config);
  },
  createOrganisation(data, config = {}) {
    return axios.post('/v1/master/organisations', data, config);
  },
  getOrganisation(organisationId, config = {}) {
    return axios.get(`/v1/master/organisations/${organisationId}`, config);
  },
  updateOrganisation(organisationId, data, config = {}) {
    return axios.put(`/v1/master/organisations/${organisationId}`, data, config);
  },
  deleteOrganisation(organisationId, config = {}) {
    return axios.delete(`/v1/master/organisations/${organisationId}`, config);
  },
};
