<template>
  <div>
    <page-heading :heading="$t('organisations')">
      <template v-slot:actions>
        <app-button :label="$t('create')" @click="openCreateModal()"></app-button>
      </template>
    </page-heading>

    <form-open class="my-4" @submit="filter">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <form-text
          :label="$t('search')"
          icon="search"
          input-id="search"
          v-model="query.filter.name"
        />
        <form-select
          :label="$t('status')"
          :multiple="true"
          :options="data.statuses"
          :placeholder="$t('select_placeholder_all')"
          :required="false"
          :show-validation-label="false"
          @input="filter"
          input-id="status"
          v-model="query.filter.status"
        />
        <app-button class="hidden"/>
      </div>
    </form-open>

    <loading-wrapper>
      <app-table :items="items">
        <template
          v-slot:headings
        >
          <app-th-sortable :label="$t('model.organisation.name')" name="name" :sort="query.sort" @updateSort="updateSort"/>
          <app-th-sortable :label="$t('model.organisation.status')" name="status" :sort="query.sort" @updateSort="updateSort"/>
          <app-th :heading="$t('notes')"/>
          <app-th/>
        </template>

        <template
          v-slot:row="{ item }"
        >
          <app-td>
            <a href="#" @click.prevent="editOrganisation(item.id)" class="link">{{ item.name }}</a>
          </app-td>
          <app-td>
            <badge class="text-sm"
              :label="$t(`organisation_status.${item.status}`)"
              :theme="getStatusTheme(item.status)"
            />
          </app-td>
          <app-td>
            <badge class="text-sm cursor-pointer"
              :label="item.notes_count"
              @click="editOrganisation(item.id, 'notes')"
            />
          </app-td>
          <app-td>
            <app-options>
              <div>
                <app-option-edit @click="editOrganisation(item.id)"/>
                <app-option :label="$t('top_level_users')" svg="user-group" @click="editOrganisation(item.id, 'users')"/>
                <app-option :label="$t('notes')" svg="chat" @click="editOrganisation(item.id, 'notes')"/>
              </div>
              <app-option-delete @click="openDeleteModal(item.id)" v-if="item.is_deletable"/>
            </app-options>
          </app-td>
        </template>
      </app-table>
      <pagination
        :meta="meta"
        :page="query.page"
        v-if="meta"
        @pageUpdated="updatePage"
      />
    </loading-wrapper>

    <slideover :active="modals.create" @close="closeModal('create')" @submit="submit"
      :title="modelId ? $t('edit') : $t('create')"
    >
      <debug>{{ model }}</debug>
      <form-text :form-id="formId" input-id="name" form-error-id="name" :label="$t('model.organisation.name')" v-model="model.name"/>
      <template v-slot:buttons>
        <app-button
          :label="$t(modelId ? 'update' : 'create')"
          :disabled="loading"
          :loading="loading"
        />
      </template>
    </slideover>

    <modal-delete :active="modals.delete" @close="closeModal('delete')" @delete="deleteModel(modelId)"
      :title="$t('delete')"
      :text="$t('delete_thing_text', { thing: model.name })"
    />
  </div>
</template>

<script>
import ApiMasterOrganisationService from '@/services/api/master/organisation';
import ApiOrganisationStatusService from '@/services/api/organisation_status';
import Common from '@/mixins/common';

export default {
  data() {
    return {
      data: {
        statuses: [],
      },
      formId: 'createOrganisation',
      items: [],
      meta: {},
      modals: {
        create: false,
        delete: false,
      },
      modelId: null,
      model: this.emptyModel(),
      query: {
        filter: {
          status: [
            1,
          ],
        },
        page: 1,
        sort: [
          'name',
        ],
        ...this.$route.query,
      },
    };
  },
  metaInfo() {
    return {
      title: this.$t('organisations'),
    };
  },
  methods: {
    deleteModel(modelId) {
      ApiMasterOrganisationService.deleteOrganisation(modelId).then(() => {
        this.closeModal('delete');
        this.get();
      }).catch(() => {});
    },
    editOrganisation(organisationId, hash = null) {
      const location = {
        name: 'master_organisations.edit',
        params: {
          organisationId,
        },
      };

      if (hash) {
        location.hash = `#${hash}`;
      }

      this.$router.push(location);
    },
    emptyModel() {
      return {};
    },
    filter() {
      this.query.page = 1;
      this.get();
    },
    get() {
      ApiMasterOrganisationService.getOrganisations({
        params: {
          ...this.query,
          include: [
            'notesCount',
          ],
        },
      })
        .then((response) => {
          const { data, meta } = response.data;

          this.items = data;
          this.meta = meta;
        })
        .catch(() => {});
    },
    getListings() {
      this.get();
    },
    getStatusTheme(statusId) {
      return {
        1: 'success',
        8: 'primary',
        9: 'error',
        10: 'warning',
      }[statusId];
    },
    openCreateModal() {
      this.clearFormErrors(this.formId);

      this.modelId = null;
      this.model = this.emptyModel();
      this.openModal('create');
    },
    openDeleteModal(modelId) {
      ApiMasterOrganisationService.getOrganisation(modelId).then((response) => {
        const { data } = response.data;

        this.modelId = data.id;
        this.model = data;
        this.openModal('delete');
      }).catch(() => {});
    },
    routeQueryUpdated() {
      this.get();
    },
    submit() {
      ApiMasterOrganisationService
        .createOrganisation(this.model, {
          formId: this.formId,
          showMessage: true,
        })
        .then((response) => {
          const { data } = response.data;

          this.editOrganisation(data.id);
        })
        .catch(() => {});
    },
  },
  mixins: [
    Common,
  ],
  mounted() {
    ApiOrganisationStatusService.getOrganisationStatuses()
      .then((response) => {
        const { data } = response.data;

        this.data.statuses = this.toSelectOptions(data, 'status', 'id', (label) => `organisation_status.${label}`);
      })
      .catch(() => {});

    this.get();
  },
};
</script>
